// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADxe5NIRTNWMNLYRtOqWQ1Bzs3obvrpwk",
  authDomain: "hollrs-app.firebaseapp.com",
  projectId: "hollrs-app",
  storageBucket: "store.hollrs.com",
  messagingSenderId: "625419389223",
  appId: "1:625419389223:web:623c7f504193c4b9f04102",
  measurementId: "G-6QFGKN05NH"
};

export const APP_VERSION = 'prod';
export const REGION = 'europe-west2';

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, REGION);
export const storage = getStorage(app, firebaseConfig.storageBucket);
