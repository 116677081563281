import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const DEBUG = true;

export const adminCleanReportedHollrsFromFeeds = async () => {
  return adminCallFunction('cleanReportedHollrsFromFeeds');
};

export const adminAnalyseMissingPosts = async () => {
  return adminCallFunction('analyseMissingPosts');
};

export const adminUpdatePostTranscripts = async () => {
  return adminCallFunction('updatePostTranscripts');
};

export const adminExtractAudioFromVideoPosts = async () => {
  return adminCallFunction('extractAudioFromVideoPosts');
};

export const adminDashboardMap = async (userID, associateID) => {
  const res = await callFunction('admin', 'dashboardMap', {
    userID,
    associateID,
  });

  return res?.data;
};

export const adminCallFunction = async (functionName) => {
  return callFunction('admin', functionName);
}

export const adminListFunction = async (functionName, page = 0, size = 25, filter = {}) => {
  return listFunction('admin', functionName, page, size, filter);
}

export const adminUpdateFunction = async (functionName, id, data) => {
  return updateFunction('admin', functionName, id, data);
}

export const callFunction = async (functionGroup, functionName, data = {}) => {
  if (DEBUG) {
    console.time('FIREBASE: ' + functionGroup + '_' + functionName);
  }
  const instance = httpsCallable(functions, functionGroup);
  try {
    const res = await instance({
      action: functionName,
      data,
    });

    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionGroup + '_' + functionName);
    }
    return res;
  } catch (error) {
    console.error(error);
    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionGroup + '_' + functionName);
    }

    return {
      error,
    };
  }
}

/**
 * List documents from Firestore using a grouped function
 * @param functionGroup
 * @param functionName
 * @param page
 * @param size
 * @param filter
 * @returns {Promise<unknown>}
 */
export const listFunction = async (functionGroup, functionName, page = 0, size = 25, filter = {}) => {
  if (DEBUG) {
    console.time('FIREBASE: ' + functionGroup + '_' + functionName);
  }
  const instance = httpsCallable(functions, functionGroup);
  try {
    const res = await instance({
      action: functionName,
      page,
      size,
      filter,
    });

    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionGroup + '_' + functionName);
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionGroup + '_' + functionName);
    }

    return {
      error,
    };
  }
};

/**
 * Update a document in Firestore using an admin function
 * @param functionGroup string
 * @param functionName string
 * @param id string
 * @param data object
 * @returns {Promise<any>}
 */
export const updateFunction = async (functionGroup, functionName, id, data) => {
  if (DEBUG) {
    console.time('FIREBASE: ' + functionGroup + '_' + functionName);
  }
  try {
    const instance = httpsCallable(functions, functionGroup);
    const res = await instance({
      action: functionName,
      id,
      data,
    });

    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionGroup + '_' + functionName);
    }

    return res?.data;
  } catch(e) {
    console.error(e);
    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionGroup + '_' + functionName);
    }

    return {
      error: e,
    };
  }
};
